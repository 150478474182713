<template>
  <div>
    <b-container class="page-container">
      <bread-crumbs currentpage="Openingsdagen" />
      <h1>Openingsdagen</h1>

      <b-row>
        <b-col md="4" sm="6" xs="12">
          <b-form-radio-group
            id="displayOptions"
            v-model="displayPeriodUom"
            label="Kalender weergave"
            label-for="displayOptions"
            class="mb-3"
          >
            <b-form-radio value="week">Week</b-form-radio>
            <b-form-radio value="month">Maand</b-form-radio>
            <b-form-radio value="year">Jaar</b-form-radio>
          </b-form-radio-group>
        </b-col>
      </b-row>

      <calendar-view
        :show-date="showDate"
        :display-period-uom="displayPeriodUom"
        :items="items"
        locale="nl"
        class="theme-default holiday-us-traditional holiday-us-official mb-5"
      >
        <template #header="{ headerProps }">
          <calendar-view-header
            slot="header"
            :header-props="headerProps"
            @input="setShowDate"
          />
        </template>
      </calendar-view>
    </b-container>
  </div>
</template>

<script>
import { CalendarView, CalendarViewHeader } from 'vue-simple-calendar'
import '@/assets/scss/simple-calendar.scss'
import dayjs from 'dayjs'

export default {
  components: { CalendarView, CalendarViewHeader },
  data: function() {
    return {
      showDate: new Date(),
      displayPeriodUom: 'month',
      items: [],
      displayOptions: [
        { value: 'week', text: 'Week' },
        { value: 'month', text: 'Maand' },
        { value: 'year', text: 'Jaar' }
      ]
    }
  },
  async created() {
    for (let day = -380; day < 380; day++) {
      this.items.push({
        id: day,
        startDate: dayjs()
          .add(day, 'days')
          .format('YYYY-MM-DD'),
        title: 'Open',
        classes: 'open'
      })
    }
  },
  methods: {
    thisMonth(d, h, m) {
      const t = new Date()
      return new Date(t.getFullYear(), t.getMonth(), d, h || 0, m || 0)
    },
    setShowDate(d) {
      this.showDate = d
    }
  }
}
</script>

<style></style>
